import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';

const Footer = () => {
    const currentYear = new Date().getFullYear();
	
    return (
        <Box py={3} bgcolor="primary.main" color="white" className="footer">
            <Container>
                <Typography align="center" variant="body1" gutterBottom>
                    © 2017–{currentYear} Bogel Enterprises | 
                    <Link href="https://www.bogelenterprises.com/legal/" target="_blank" rel="noopener noreferrer" color="inherit">Legal</Link> | 
                    <Link href="https://www.bogelenterprises.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer" color="inherit">Privacy Policy</Link> | 
                    <Link href="https://www.bogelenterprises.com/legal/advertising-policy/" target="_blank" rel="noopener noreferrer" color="inherit">Advertising Policy</Link> | 
                    <Link href="https://www.bogelenterprises.com/legal/terms-of-use/" target="_blank" rel="noopener noreferrer" color="inherit">Terms of Use</Link>
                </Typography>
                <Typography align="center" variant="body2">
                    <Link href="https://www.bogelenterprises.com/legal/trademark-notice/" target="_blank" rel="noopener noreferrer" color="inherit">All logos, trademarks and registered trademarks are the property of their respective owners.</Link>As an Amazon Associate we earn from qualifying purchases.
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;