import React, { useState, useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import theme from './styles/theme';
import Chip from '@mui/material/Chip';
import Navbar from './components/Navbar';
import IntroContainer from './components/IntroContainer';
import GiftRecommendationForm from './components/GiftRecommendationForm';
import OutputDisplay from './components/OutputDisplay';
import ResultsIntro from './components/ResultsIntro';
import ResultsLoading from './components/ResultsLoading';
import Footer from './components/Footer';


const MainApp = () => {
    const [recommendations, setRecommendations] = useState([]);
    const [age, setAge] = useState('null');
    const [gender, setGender] = useState('null');
    const [budget, setBudget] = useState('null');
    const [interests, setInterests] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [isFadingOut, setIsFadingOut] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(true);
	const location = useLocation();
	const resetState = () => {
		setRecommendations([]);
			setAge('null');
			setGender('null');
			setBudget('null');
			setInterests([]);
			setShowResults(false);
			setIsFadingOut(false);
			setIsFormVisible(true);
		};

    const formRef = useRef(null);
    const navigate = useNavigate();

    const fadeClassForm = isFadingOut ? 'fade-out' : 'fade-in';
    const fadeClassResults = showResults ? 'fade-in' : 'fade-out';

	useEffect(() => {
		const handleTransitionEnd = () => {
			if (showResults) {
				setIsFormVisible(false);
			}
		};

		const formElement = formRef.current;
		if (formElement) {
			formElement.addEventListener('transitionend', handleTransitionEnd);

			return () => {
				formElement.removeEventListener('transitionend', handleTransitionEnd);
			};
		}
	}, [showResults]);

	useEffect(() => {
		// If the user is on the /results page, recommendations is empty, and showResults is false
		if (location.pathname === "/results" && recommendations.length === 0 && !showResults) {
			navigate("/"); // Navigate to the main page
		}
	}, [location.pathname, recommendations, showResults, navigate]);

	useEffect(() => {
		if (location.pathname === "/") {
			resetState();
		}
	}, [location.pathname]);


    const handleRecommendationReceived = (data) => {
        setRecommendations(data);
        setIsFadingOut(true);
        setTimeout(() => {
            setShowResults(true);
            navigate("/results"); // Navigate to results page
        }, 1000);
    };

    const getArticleForAge = (age) => {
        if ([8, 11, 18].includes(age)) {
            return "an";
        }
        return "a";
    };

	const generateSearchElements = () => {
		let elements = [];
		
		const article = getArticleForAge(age);
		elements.push(<span>{`${article} `}</span>);
		
		elements.push(<Chip label={`${age}-year-old`} className="search-choice-chip" />);

		if (gender !== 'null') {
			elements.push(<span> </span>);
			elements.push(<Chip label={gender} className="search-choice-chip" />);
		}

		if (interests.length > 0) {
			elements.push(<span> interested in </span>);

			if (interests.length === 1) {
				elements.push(<Chip label={interests[0]} className="search-choice-chip" />);
			} else if (interests.length === 2) {
				elements.push(<Chip label={interests[0]} className="search-choice-chip" />);
				elements.push(<span> and </span>);
				elements.push(<Chip label={interests[1]} className="search-choice-chip" />);
			} else {
				interests.slice(0, interests.length - 1).forEach((interest, index) => {
					elements.push(<Chip label={interest} className="search-choice-chip" />);
					elements.push(<span>, </span>);
				});
				elements.push(<span>and </span>);
				elements.push(<Chip label={interests[interests.length - 1]} className="search-choice-chip" />);
			}
		}

		if (budget !== 'null') {
			elements.push(<span> with a budget of </span>);
			elements.push(<Chip label={budget} className="search-choice-chip" />);
		}

		return elements;
	};


	return (
		<div className="app">
			<Navbar />
			<main className="main-container">
				<Routes>
					<Route path="/" element={
						<div style={{ position: 'relative' }}>
							{isFormVisible && (
								<div className={fadeClassForm} ref={formRef}>
									<IntroContainer />
									<GiftRecommendationForm 
										onRecommendationReceived={handleRecommendationReceived} 
										age={age}
										gender={gender}
										budget={budget}
										interests={interests}
										setAge={setAge}
										setGender={setGender}
										setBudget={setBudget}
										setInterests={setInterests}
									/>
								</div>
							)}
						</div>
					} />
					<Route path="/results" element={
						<div className={fadeClassResults}>
							{showResults && <ResultsIntro searchElements={generateSearchElements()} />}
							{showResults && recommendations.length === 0 && <ResultsLoading />}
							{showResults && recommendations.length > 0 &&
								<OutputDisplay 
									recommendations={recommendations} 
									age={age}
									gender={gender}
									budget={budget}
									interests={interests}
									onRecommendationReceived={handleRecommendationReceived}
								/>
							}
						</div>
					} />
				</Routes>
			</main>
			<Footer />
		</div>
    );
}

const App = () => {
    useEffect(() => {
        const tagManagerArgs = {
            gtmId: 'GTM-NWRSP6XN'
        };
        TagManager.initialize(tagManagerArgs);
    }, []);
	
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path="*" element={<MainApp />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;