import React from 'react';

const IntroContainer = () => {
    return (
        <div className="intro-container">
            <h1>Elevate Your Gift Game with AI Magic!</h1>
            <div className="intro-card">
                <p>
                    Discover a world of unique and personalized gift suggestions for children with our innovative app! 🎁 No matter their age or interests, our AI-powered tool curates a diverse selection of gifts that cater to their curiosity and creativity. 🎨 Whether you're searching for educational toys to spark their intellect or playful items to fuel their imagination, our app makes finding the perfect gift a breeze. 🎉 Dive into a tailored gift-finding experience that grows with them, ensuring each celebration is as unique and special as they are. 🎂
                </p>
            </div>
        </div>
    );
}

export default IntroContainer;