import React, { useState, useEffect } from 'react';

const endpointRoot = `https://api.findakidsgift.com/`;

const OutputDisplay = ({ recommendations, age, gender, budget, interests, onRecommendationReceived }) => {
    const US_AFFILIATE_CODE = 'findakidsgift-20';
    const [isLoading, setIsLoading] = useState(false);
    const [displayedCards, setDisplayedCards] = useState([]);

    useEffect(() => {
        if (recommendations.length === 0) return;

        const interval = setInterval(() => {
            if (displayedCards.length < recommendations.length) {
                const nextRecommendation = recommendations[displayedCards.length];
                if (nextRecommendation) {
                    setDisplayedCards(prev => [...prev, nextRecommendation]);
                }
            } else {
                clearInterval(interval);
            }
        }, 500);

        return () => clearInterval(interval);
    }, [recommendations, displayedCards]);

    const buyOnAmazon = (event, searchTerm) => {
        event.stopPropagation();
        let formattedSearchTerm = encodeURIComponent(searchTerm);
        let amazonURL = `https://www.amazon.com/s?field-keywords=${formattedSearchTerm}&tag=${US_AFFILIATE_CODE}`;
        window.open(amazonURL, '_blank');
    };

    const loadMore = async () => {
        setIsLoading(true);

		window.dataLayer.push({
			'event': 'loadMoreButtonClick'
		});

        const serverEndpoint = `${endpointRoot}recommend`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                age: age,
                gender: gender,
                budget: budget,
                interests: interests,
                currentRecommendations: recommendations
            })
        };

        try {
            const serverResponse = await fetch(serverEndpoint, requestOptions);
            setIsLoading(false);

            if (serverResponse.ok) {
                const responseData = await serverResponse.json();
                const newRecommendations = JSON.parse(responseData.msg);
                if (Array.isArray(newRecommendations)) {
                    onRecommendationReceived([...recommendations, ...newRecommendations]);
                } else {
                    console.error('The server responded with an unexpected format.');
                }
            } else {
                console.error('There was an error fetching recommendations:', serverResponse.statusText);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('There was an error:', error.message);
        }
    };

    return (
        <section className="card-container">
            {displayedCards.map((rec, index) => (
                <div className="card bounce" key={index}>
                    <div className="header-content">
                        <h3>{rec}</h3>
                        <button className="amazon-button" id={`amazon-button-${index}`} name={rec} onClick={(e) => buyOnAmazon(e, rec)}>
                            Buy on Amazon
                        </button>
                    </div>
                </div>
            ))}
            {recommendations.length > 0 && recommendations.length < 50 && (
                <button className={`load-more-button${isLoading ? " loading" : ""}`} onClick={loadMore} disabled={isLoading}>
                    <span>{isLoading ? "Recommendations Loading" : "Load More Recommendations"}</span>
                </button>
            )}
        </section>
    );
}

export default OutputDisplay;
