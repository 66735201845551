import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
const endpointRoot = `https://api.findakidsgift.com/`;

const GiftRecommendationForm = ({ 
    onRecommendationReceived, 
    age, setAge, 
    gender, setGender, 
    budget, setBudget, 
    interests, setInterests 
}) => {
    const [currentInterest, setCurrentInterest] = useState('');
    const [showWarning, setShowWarning] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [formErrors, setFormErrors] = useState({});

    const addInterestChip = (interest) => {
        const titleCaseInterest = interest.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

        if (interests.length >= 5) {
            setShowWarning(true);
            return;
        } else {
            setShowWarning(false);
        }
        setInterests(prevInterests => [...prevInterests, titleCaseInterest]);
    };

    const removeInterestChip = (interestToRemove) => {
        setInterests(prevInterests => prevInterests.filter(interest => interest !== interestToRemove));
    };

    const handleInterestKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (currentInterest.trim()) {
                addInterestChip(currentInterest.trim());
                setCurrentInterest('');
            }
        }
    };

	const validateForm = () => {
		const errors = {};
		if (age === "null" || age === null) {
			errors.age = "Child's age is required.";
		}
		return errors;
	};

    const handleFormSubmit = async (event) => {
		event.preventDefault();

		const errors = validateForm();
		setFormErrors(errors);

		if (Object.keys(errors).length > 0) return;
		
		window.dataLayer.push({
			'event': 'recommendationFormSubmission',
			'age': age,
			'gender': gender,
			'budget': budget,
			'interests': interests
		});
		
		setFormSubmitted(true);
		onRecommendationReceived([]);
		
		// Define the server endpoint where we will send the POST request
		const serverEndpoint = `${endpointRoot}recommend`;

		// Prepare the request options for the fetch function
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				age: age,
				gender: gender,
				budget: budget,
				interests: interests
			})
		};

		try {
			// Send the POST request to the server
			const serverResponse = await fetch(serverEndpoint, requestOptions);

			// If the request was successful, process the response
			if (serverResponse.ok) {
				const responseData = await serverResponse.json();

				// Parse the 'msg' property string into a JavaScript array
				const recommendationsArray = JSON.parse(responseData.msg);

				// Check if the 'msg' property from the response contains an array
				if (Array.isArray(recommendationsArray)) {
					onRecommendationReceived(recommendationsArray);
				} else {
					console.error('The server responded with an unexpected format.');
				}
			} else {
				console.error('There was an error fetching recommendations from the server:', serverResponse.statusText);
			}
		} catch (error) {
			console.error('There was an error while processing the request:', error.message);
		}
	};


    return (
        <section className="gift-recommendation-form">
            <form onSubmit={handleFormSubmit}>
                <div className="form-container">
					<div className="input-row">
						<div className="input-field">
							<label htmlFor="age">Child's Age*</label>
							<Select
								className="select-input"
								id="age"
								value={age}
								onChange={e => {
									setAge(e.target.value);
									// Clear age error if it exists
									if (formErrors.age) {
										setFormErrors(prevErrors => {
											const newErrors = { ...prevErrors };
											delete newErrors.age;
											return newErrors;
										});
									}
								}}
								variant="outlined"
							>
								<MenuItem value="null">How old is the child?</MenuItem>
								{Array.from({ length: 19 }, (_, index) => (
									<MenuItem key={index} value={index}>{index}</MenuItem>
								))}
							</Select>
							{formErrors.age && <span style={{ marginLeft: '10px', color: 'red', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>{formErrors.age}</span>}
						</div>
						<div className="input-field">
							<label htmlFor="gender">Child's Gender</label>
							<Select
								className="select-input"
								id="gender"
								value={gender}
								onChange={e => setGender(e.target.value)}
								variant="outlined"
							>
								<MenuItem value="null">Don't Want to Say</MenuItem>
								<MenuItem value="Boy">Boy</MenuItem>
								<MenuItem value="Girl">Girl</MenuItem>
								<MenuItem value="Non-Binary Child">Non-Binary Child</MenuItem>
							</Select>
						</div>
						<div className="input-field">
							<label htmlFor="budget">Budget</label>
							<Select
								className="select-input"
								id="budget"
								value={budget}
								onChange={e => setBudget(e.target.value)}
								variant="outlined"
							>
								<MenuItem value="null">How much do you want to spend?</MenuItem>
								<MenuItem value="under $10">under $10</MenuItem>
								<MenuItem value="$10-25">$10-25</MenuItem>
								<MenuItem value="$25-50">$25-50</MenuItem>
								<MenuItem value="$50-100">$50-100</MenuItem>
								<MenuItem value="$100+">$100+</MenuItem>
							</Select>
						</div>
					</div>
					<div className="interests-container">
						<label htmlFor="interest-input">Enter up to 5 Interests</label>
						<div className="interest-input-container">
							<TextField
								id="interest-input"
								className="interest-input"
								value={currentInterest}
								onChange={e => setCurrentInterest(e.target.value)}
								onKeyDown={handleInterestKeyDown}
								placeholder="Type and press enter..."
								variant="outlined"
							/>
							{showWarning && <span style={{ marginLeft: '10px', color: 'red', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>You can only add up to 5 interests!</span>}
						</div>
						<div className="interests-chips">
							{interests.map(interest => (
								<Chip
									className="interest-chip"
									key={interest}
									label={interest}
									onDelete={() => removeInterestChip(interest)}
								/>
							))}
						</div>
					</div>
					<div className="button-wrapper">
						<button type="submit" className="submit-button" id="gift-recommendation-form-submit-button" disabled={formSubmitted}>
							Suggest Some Special Gifts
						</button>
					</div>
				</div>
            </form>
        </section>
    );
}

export default GiftRecommendationForm;
