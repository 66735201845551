const ResultsIntro = ({ searchElements }) => {

    return (
        <div className="loading-message-container">
            <h1>Unique Gift Ideas for:</h1>
			<h2>
                {searchElements.map((element, index) => (
                    <span key={index}>{element}</span>
                ))}
            </h2>
        </div>
    );
}

export default ResultsIntro;
