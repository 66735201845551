const ResultsLoading = () => {

    return (
        <div className="loading-message-container">
			<button className={`load-more-button loading`} disabled={true}>
				<span>Recommendations Loading</span>
			</button>
        </div>
    );
}

export default ResultsLoading;
