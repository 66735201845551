import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#004466',
    },
    secondary: {
      main: '#E83E8C',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#292D31',
      secondary: '#767B80',
    },
  },
});

export default theme;
